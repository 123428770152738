import { createRoot } from 'react-dom/client';
import 'swiper/css/bundle';

import './style.scss';
import APBPosts from './Components/Frontend/APBPosts';

document.addEventListener('DOMContentLoaded', () => {
	const postsEls = document.querySelectorAll('.wp-block-ap-block-posts');
	postsEls.forEach(postsEl => {
		const nonce = JSON.parse(postsEl.dataset.nonce);
		const attributes = JSON.parse(postsEl.dataset.attributes);
		const { totalPosts } = JSON.parse(postsEl.dataset.extra);
		const firstPosts = JSON.parse(postsEl.querySelector('pre#firstPosts').innerText);

		createRoot(postsEl).render(<APBPosts firstPosts={firstPosts} nonce={nonce} totalPosts={totalPosts} attributes={attributes} id={postsEl.id} />);

		postsEl?.removeAttribute('data-nonce');
		postsEl?.removeAttribute('data-attributes');
		postsEl?.removeAttribute('data-extra');
	});
});